<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: ESM - EASY SCHOOL MANAGEMENT
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <!-- ROW 1-->
    <div class="vx-row">
       
       <div  class="vx-col w-full mb-base">

          <vx-card v-if="showByAdmin === 'leadProject' || showByAdmin === 'admin'" ref="filterCard" title="Filtre" class="user-list-filters mb-4"  >
          <div class="vx-row">
            <div class="vx-col w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5">
              <p class="mb-2" > {{ $t('project') }} </p>
              <v-select v-model="project" :options="projects" :reduce="rep => rep.id" label="name" autocomplete >
              </v-select>
            </div>
            <div class="vx-col w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5">
              <p class="mb-2" > {{ $t('company') }} </p>
              <v-select name="company" :reduce="rep => rep.id" class="w-full" label="name" v-model="company" :options="companies">
              </v-select>
            </div>
            <div class="vx-col w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5">
              <p class="mb-2" > {{ $t('country') }} </p>
              <v-select name="country" :reduce="rep => rep.id" class="w-full" label="name" v-model="country" :options="nationalities">
              </v-select>
            </div>

            <div class="vx-col w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5">
              <p class="mb-2" > {{ $t('Date_début') }} </p>
              <flat-pickr 
                :config="configdateTimePicker1" 
                @on-change="onFromChange" 
                label-placeholder=" Date"
                v-model="start_date"
                name="start_date"
                class="w-full" />
            </div>

            <div class="vx-col w-full sm:w-1/5 md:w-1/5 lg:w-1/5 xl:w-1/5">
              <p class="mb-2" > {{ $t('Date_fin') }} </p>
              <flat-pickr 
                :config="configdateTimePicker2" 
                @on-change="onToChange"
                label-placeholder=" Date"
                name="end_date"
                v-model="end_date"
                class="w-full" />
            </div>

          </div>
          </vx-card>

          <div class="vx-row">
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="teams()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.team_count || 0) )"
                :statisticTitle="$t('teams')"/>
            </div>
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="inactivation_teams()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                color="warning"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.inactivation_teams || 0) )"
                :statisticTitle="$t('inactivation_teams')"/>
            </div>
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="activated_teams()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                color="success"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.activated_teams || 0) )"
                :statisticTitle="$t('activated_teams')"/>
            </div>
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="rejected_teams()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                color="danger"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.rejected_teams || 0) )"
                :statisticTitle="$t('rejected_teams')"/>
            </div>
            <div class=" cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="vehicles()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TruckIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.vehicles || 0) )"
                :statisticTitle="$t('vehicles')"  />
            </div>
            <div class=" cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="inactivation_vehicles()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TruckIcon"
                icon-right
                color="warning"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.inactivation_vehicles || 0) )"
                :statisticTitle="$t('inactivation_vehicles')"  />
            </div>
            <div class=" cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="activated_vehicles()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TruckIcon"
                icon-right
                color="success"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.activated_vehicles || 0) )"
                :statisticTitle="$t('activated_vehicles')"  />
            </div>
            <div class=" cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="rejected_vehicles()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TruckIcon"
                icon-right
                color="danger"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.rejected_vehicles || 0) )"
                :statisticTitle="$t('rejected_vehicles')"  />
            </div>
          
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="staffs()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.staffs || 0) )"
                :statisticTitle="$t('staffs')"/>
            </div>
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="inactivation_staffs()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                color="warning"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.inactivation_staffs || 0) )"
                :statisticTitle="$t('inactivation_staffs')"/>
            </div>
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="activated_staffs()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                color="success"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.activated_staffs || 0) )"
                :statisticTitle="$t('activated_staffs')"/>
            </div>
            <div class="cursor-pointer vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="rejected_staffs()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UserPlusIcon"
                icon-right
                color="danger"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.rejected_staffs || 0) )"
                :statisticTitle="$t('rejected_staffs')"/>
            </div>
            <div class="cursor-pointer  vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="missions()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TargetIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.mission_count || 0) )"
                :statisticTitle="$t('missions')"  />
            </div>
            <div class="cursor-pointer  vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="unclosedMissions()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TargetIcon"
                icon-right
                color="warning"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.unclosedMissions || 0) )"
                :statisticTitle="$t('unclosedMissions')"  />
            </div>
            <div class="cursor-pointer  vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="enclosedMissions()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TargetIcon"
                icon-right
                color="danger"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.enclosed_missions || 0) )"
                :statisticTitle="$t('enclosedMissions')"  />
            </div>

            <div v-if="showByAdmin ==='admin'" class="cursor-pointer  vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="liftingEquipments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="LayersIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.liftingEquipments || 0) )"
                :statisticTitle="$t('liftingEquipments')"  />
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer  vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="inactivation_liftingEquipments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="LayersIcon"
                color="warning"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.inactivation_liftingEquipments || 0) )"
                :statisticTitle="$t('inactivation_liftingEquipments')"  />
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer  vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="activated_liftingEquipments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="LayersIcon"
                color="success"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.activated_liftingEquipments || 0) )"
                :statisticTitle="$t('activated_liftingEquipments')"  />
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer  vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="rejected_liftingEquipments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="LayersIcon"
                icon-right
                color="danger"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.rejected_liftingEquipments || 0) )"
                :statisticTitle="$t('rejected_liftingEquipments')"  />
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer vx-col  w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="equipments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="LayersIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.equipments || 0) )"
                :statisticTitle="$t('equipments')"/>
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer vx-col  w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="inactivation_equipments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="LayersIcon"
                icon-right
                color="warning"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.inactivation_equipments || 0) )"
                :statisticTitle="$t('inactivation_equipments')"/>
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer vx-col  w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="activated_equipments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="LayersIcon"
                color="success"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.activated_equipments || 0) )"
                :statisticTitle="$t('activated_equipments')"/>
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer vx-col  w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4" @click="rejected_equipments()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="LayersIcon"
                icon-right
                color="danger"
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.rejected_equipments || 0) )"
                :statisticTitle="$t('rejected_equipments')"/>
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="companies_()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="GlobeIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.companies || 0) )"
                :statisticTitle="$t('companies')"/>
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="projects_()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="TagIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.project_count || 0) )"
                :statisticTitle="$t('projects')"/>
            </div>
            <div  v-if="showByAdmin ==='admin'" class="cursor-pointer vx-col w-full sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3" @click="meetings()" >
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ClockIcon"
                icon-right
                :statistic="new Intl.NumberFormat('de-DE').format( ( variable_dashboard.meeting_count || 0) )"
                :statisticTitle="$t('meetings')"/>
            </div>
           <!--<chartjs-bar-chart></chartjs-bar-chart>-->
          </div>

       </div>
    </div> 

  </div>
</template>

<script>


import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import analyticsData from '@/views/ui-elements/card/analyticsData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'
import ChartjsBarChart from '/src/views_mitigarisk/statistiqueGraph/ChartjsBarChart'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr
export default {
  data () {
    return {
      projects:[],
      companies:[],
      nationalities:[],
      start_date:'',
      end_date:'',
      project:'',
      company:'',
      country:'',
      showByAdmin: '',
      dashboardValue: false,
      showByContributors: false,
      variable_dashboard : '',
      analyticsData,
      filter_appointment:null,
      configdateTimePicker1: {
        enableTime: 'fasle',
        minDate: null,
        maxDate: new Date(),
        dateFormat: 'Y-m-d',
        locale: French
      },
      configdateTimePicker2: {
        enableTime: 'fasle',
        maxDate: new Date(),
        minDate: null,
        dateFormat: 'Y-m-d',
        locale: French
      }
    }
  },

  components: {
    flatPickr,
    VueApexCharts,
    ChartjsBarChart,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline
  },
  watch:{
    project (value) {
      if (value) {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: value,
            start_date: this.start_date,
            end_date : this.end_date,
            company_id: this.company,
            country_id : this.country
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            start_date: this.start_date,
            end_date : this.end_date,
            company_id: this.company,
            country_id : this.country
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      }
   
    },
    start_date (value) {
      if (value) {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: this.project,
            start_date: value,
            end_date : this.end_date,
            company_id: this.company,
            country_id : this.country
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: this.project,
            end_date : this.end_date,
            company_id: this.company,
            country_id : this.country
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      }
   
    },
    end_date (value) {
      if (value) {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: this.project,
            start_date: this.start_date,
            end_date : value,
            company_id: this.company,
            country_id : this.country
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: this.project,
            start_date: this.start_date,
            company_id: this.company,
            country_id : this.country
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      }
   
    },
    company (value) {
      if (value) {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: this.project,
            start_date: this.start_date,
            end_date : this.end_date,
            company_id: value,
            country_id : this.country
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: this.project,
            start_date: this.start_date,
            end_date : this.end_date,
            country_id : this.country
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      }
   
    },
    country (value) {
      if (value) {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: this.project,
            start_date: this.start_date,
            end_date : this.end_date,
            company_id: this.company,
            country_id : value
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.$vs.loading()
        this.$http.get('dashboard-leadProject/', {
          params: {
            project_id: this.project,
            start_date: this.start_date,
            end_date : this.end_date,
            company_id: this.company
          }
        })
          .then((response) => {
            this.variable_dashboard = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      }
   
    }
  },
  methods: {
    onToChange (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker1, 'maxDate', dateStr)
    },
    onFromChange (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker2, 'minDate', dateStr)
    },
    missions () {
      this.$router.push('/Planifications').catch(() => {})
    },
    unclosedMissions () {
      this.$router.push('/Unclosed-missions').catch(() => {})
    },
    enclosedMissions () {
      this.$router.push('/Closed-missions').catch(() => {})
    },
    teams () {
      this.$router.push('/Teams').catch(() => {})
    },
    inactivation_teams () {
      this.$router.push('/Inactivation-teams').catch(() => {})
    },
    activated_teams () {
      this.$router.push('/Activated-teams').catch(() => {})
    },
    rejected_teams () {
      this.$router.push('/Rejected-teams').catch(() => {})
    },
    staffs () {
      this.$router.push('/Staffs').catch(() => {})
    },
    inactivation_staffs () {
      this.$router.push('/Inactivation-staffs').catch(() => {})
    },
    activated_staffs () {
      this.$router.push('/Activated-staffs').catch(() => {})
    },
    rejected_staffs () {
      this.$router.push('/Rejected-staffs').catch(() => {})
    },
    vehicles () {
      this.$router.push('/Vehicules').catch(() => {})
    },
    inactivation_vehicles () {
      this.$router.push('/Inactivation-vehicle').catch(() => {})
    },
    activated_vehicles () {
      this.$router.push('/Activated-vehicle').catch(() => {})
    }, 
    rejected_vehicles () {
      this.$router.push('/Rejected-vehicle').catch(() => {})
    },
    liftingEquipments () {
      this.$router.push('/LiftingEquipments').catch(() => {})
    },
    inactivation_liftingEquipments () {
      this.$router.push('/Inactivation-liftingEquipments').catch(() => {})
    },
    activated_liftingEquipments () {
      this.$router.push('/Activated-liftingEquipments').catch(() => {})
    },
    rejected_liftingEquipments () {
      this.$router.push('/Rejected-liftingEquipments').catch(() => {})
    },
    equipments () {
      this.$router.push('/Equipments').catch(() => {})
    },
    inactivation_equipments () {
      this.$router.push('/Inactivation-equipments').catch(() => {})
    },
    activated_equipments () {
      this.$router.push('/Activated-equipments').catch(() => {})
    },
    rejected_equipments () {
      this.$router.push('/Rejected-equipments').catch(() => {})
    },
    companies_ () {
      this.$router.push('/Companies').catch(() => {})
    },
    projects_ () {
      this.$router.push('/Projects').catch(() => {})
    },
    meetings () {
      this.$router.push('/Meetings').catch(() => {})
    },
    dashboard () {
      this.$vs.loading()
      this.$http.get('dashboard-leadProject/', {
        params: {
          project_id: this.project,
          start_date: this.start_date,
          end_date : this.end_date,
          company_id: this.company,
          country_id : this.country
        }
      })
        .then((response) => {
          this.variable_dashboard = response.data
          this.$vs.loading.close()
        })
    },
  
    dashboardRefrech () {
      this.$http.get('dashboard-leadProject/', {
        params: {
          project_id: this.project,
          start_date: this.start_date,
          end_date : this.end_date,
          company_id: this.company,
          country_id : this.country
        }
      })
        .then((response) => {
          this.variable_dashboard = response.data
        })
    },
    dashboard2 () {
      this.$vs.loading()
      this.$http.get('dashboardQhs/')
        .then((response) => {
          this.variable_dashboard = response.data
          this.$vs.loading.close()
        })
    },
    dashboardRefrech2 () {
      this.$http.get('dashboardQhs/')
        .then((response) => {
          this.variable_dashboard = response.data
        })
    },
    getAllNationalities () {
      this.$vs.loading()
      this.$http.get('nationalities/')
        .then((response) => {
          this.nationalities = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllCompanies () {
      this.$vs.loading()
      this.$http.get('companies/')
        .then((response) => {
          this.companies = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllProjects () {
      this.$vs.loading()
      this.$http.get('projects/')
        .then((response) => {
          this.projects = response.data          
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }

  },
  created () {
    const user_role = JSON.parse(localStorage.getItem('userInfo')).role
    this.showByAdmin = user_role
    if (user_role === 'leadProject' || user_role === 'admin' || user_role === 'qhsNokia' || user_role === 'rom') {
      this.dashboard()
      this.counterInterval =  setInterval(
        function () {
          this.dashboardRefrech()
        }.bind(this), 60000)
      if (user_role === 'leadProject' || user_role === 'admin') {
        this.getAllNationalities()
        this.getAllCompanies()
        this.getAllProjects()
      }
    }
    if (user_role === 'managerSubcontractor') {
      this.dashboard2()
      this.counterInterval =  setInterval(
        function () {
          this.dashboardRefrech2()
        }.bind(this), 60000)
    }
  },
  destroyed () {
    clearInterval(this.counterInterval)
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
